import React from "react";
import "../../styles/testimonial.css";

import Slider from "react-slick";

import ava01 from "../../images/ava-1.jpg";
import ava02 from "../../images/ava-2.jpg";
import ava03 from "../../images/ava-3.jpg";

const Testimonial = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
  };
  return (
    <section>
      <div className="container">
        <div className="slider__content-top">
          <h6 className="subtitle">Testimonials</h6>
          <h2>
            Trusted by more than{" "}
            <span className="highlight">30 customers</span>
          </h2>
        </div>

        <div className="slider__wrapper">
          <Slider {...settings}>
            <div className="slider__item">
              <p className="description">
              Exceptional service from this marketing agency has skyrocketed my brand’s success.
              Their innovative strategies and personalized care have significantly boosted engagement and sales.
              The team’s expertise is unmatched, leading to impressive growth and visibility.
              I’m extremely satisfied with the professional experience and remarkable results.
              Highly recommend for anyone seeking transformative marketing solutions.
              </p>

              <div className="customer__details">
                <div className="customer__img">
                  <img src={ava01} alt="" />
                </div>

                <div>
                  <h5 className="customer__name">Jhon Doe</h5>
                  <p className="description">CEO, Workcreation</p>
                </div>
              </div>
            </div>

            <div className="slider__item">
              <p className="description">
              I’m absolutely thrilled with the outstanding service from this marketing agency.
              Their innovative approach has transformed my brand’s reach, driving engagement and sales through the roof.
              The team’s expertise and personalized care have made a significant impact.
              I couldn’t be happier with the results and the professional experience they’ve provided. Truly top-notch!
              </p>

              <div className="customer__details">
                <div className="customer__img">
                  <img src={ava02} alt="" />
                </div>

                <div>
                  <h5 className="customer__name">Anney Martin</h5>
                  <p className="description">Software Developer</p>
                </div>
              </div>
            </div>

            <div className="slider__item">
              <p className="description">
              Partnering with this marketing agency has been a transformative experience for my company.
              Their cutting-edge strategies and tailored attention have elevated my brand’s profile and dramatically enhanced customer engagement and revenue. 
              The results have been astounding, surpassing all my expectations. The service provided is unparalleled, and I am thoroughly impressed. 
              I wholeheartedly endorse their services to any business looking to make a significant impact in their market.
              </p>

              <div className="customer__details">
                <div className="customer__img">
                  <img src={ava03} alt="" />
                </div>

                <div>
                  <h5 className="customer__name">William Cooper</h5>
                  <p className="description">Sr. Product Designer</p>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
