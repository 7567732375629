import React from "react";
import "../../styles/about.css";

import aboutImg from "../../images/about-us.jpg";

const chooseData = [
  {
    icon: "ri-wifi-line",
    title: "First working process",
    desc: "We do not take any money from doctors in exchange for our help until they are sure that the quality of our services is the best in the market.",
  },

  {
    icon: "ri-team-line",
    title: "Dedicated team",
    desc: "We have a team that specializes in the services that we provide, and this team has many years of experience in the field, and therefore you should be assured that your art and your work with us is in safe hands.",
  },
  {
    icon: "ri-customer-service-2-line",
    title: "24/7 Hours support",
    desc: "We are available throughout the week to answer your questions and assist you, so do not hesitate to ask any questions.",
  },
];

const About = () => {
  return (
    <section id="about">
      <div className="container">
        <div className="about__wrapper">
          <div className="about__content">
            <h6 className="subtitle">Why choose us</h6>
            <h2>Specialist in advising doctors on</h2>
            <h2 className="highlight"> marketing challenges</h2>
            <p className="description about__content-desc">
            Doctors at this time of time are facing many marketing problems,
            and we can summarize some of them as follows. Most doctors face a patient who makes a reservation but never comes.
            Another problem is the lack of visitors who offer to go to the doctor, and also patients do not recommend the doctor to their friends,
            and here comes the area. Our specialty is helping doctors solve their problems easily and effortlessly.
            </p>

            <div className="choose__item-wrapper">
              {chooseData.map((item, index) => (
                <div className="choose__us-item" key={index}>
                  <span className="choose__us-icon">
                    <i class={item.icon}></i>
                  </span>
                  <div>
                    <h4 className="choose__us-title">{item.title}</h4>
                    <p className="description">{item.desc}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="about__img">
            <img src={aboutImg} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
