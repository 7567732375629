import React from "react";
import "../../styles/services.css";

const serviceData = [
  {
    icon: "ri-apps-line",
    title: "Reminder System",
    desc: "A reminder system alerts users about important events, tasks, or deadlines through notifications, ensuring timely completion and organization.",
  },

  {
    icon: "ri-code-s-slash-line",
    title: "Web Design",
    desc: "Web design involves creating visually appealing and user-friendly websites, focusing on layout, color schemes, and interactive elements.",
  },

  {
    icon: "ri-landscape-line",
    title: "Social Media Management",
    desc: "creating and analyzing content across platforms to engage audiences,build brand presence, and drive growth.",
  },

  {
    icon: "ri-rocket-line",
    title: "Digital Marketing",
    desc: "Digital marketing promotes products or services using digital channels like social media, search engines, email, and websites to reach consumers.",
  },
];

const Services = () => {
  return (
    <section id="service">
      <div className="container">
        <div className="services__top-content">
          <h6 className="subtitle">Our Services</h6>
          <h2>Save time managing your business with</h2>
          <h2 className="highlight"> our best services</h2>
        </div>

        <div className="service__item-wrapper">
          {serviceData.map((item, index) => (
            <div className="services__item" key={index}>
              <span className="service__icon">
                <i class={item.icon}></i>
              </span>
              <h3 className="service__title">{item.title}</h3>
              <p className="description">{item.desc}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
